
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheAdjustment from '@/pages/leverage/components/TheAdjustment.vue'
import TheLeverLearn from '@/pages/leverage/components/TheLeverLearn.vue'
import { defineComponent, shallowRef } from 'vue'
enum TABINDEX {
  ADJUSTMENT,
  LEARN,
}

export default defineComponent({
  name: 'Leverage',
  components: {
    PageWithHeader,
    TheAdjustment,
    TheLeverLearn,
  },

  setup () {
    const activeIndex = shallowRef(TABINDEX.ADJUSTMENT)
    const tabChange = () => {
      activeIndex.value = activeIndex.value === TABINDEX.ADJUSTMENT ? TABINDEX.LEARN : TABINDEX.ADJUSTMENT
    }
    return {
      activeIndex,
      tabChange,
    }
  },
})
