<template>
  <PageWithHeader>
    <template #title><t path="leverage_2">Leverage Adjustment</t></template>
    <div>
      <div class="tabs-wrap df-middle">
        <div class="tab-item flex-1 df-center" :class="{'active':activeIndex===0}" @click="tabChange()">
          <t path="leverage_3">Adjustment</t>
        </div>
        <div class="tab-item flex-1 df-center"  :class="{'active':activeIndex===1}" @click="tabChange()">
          <t path="leverage_4">Learn about leverage</t>
        </div>
      </div>
      <TheAdjustment v-if="activeIndex===0" />
      <TheLeverLearn v-else />
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheAdjustment from '@/pages/leverage/components/TheAdjustment.vue'
import TheLeverLearn from '@/pages/leverage/components/TheLeverLearn.vue'
import { defineComponent, shallowRef } from 'vue'
enum TABINDEX {
  ADJUSTMENT,
  LEARN,
}

export default defineComponent({
  name: 'Leverage',
  components: {
    PageWithHeader,
    TheAdjustment,
    TheLeverLearn,
  },

  setup () {
    const activeIndex = shallowRef(TABINDEX.ADJUSTMENT)
    const tabChange = () => {
      activeIndex.value = activeIndex.value === TABINDEX.ADJUSTMENT ? TABINDEX.LEARN : TABINDEX.ADJUSTMENT
    }
    return {
      activeIndex,
      tabChange,
    }
  },
})
</script>

<style scoped lang="scss">
.tabs-wrap{
  .tab-item{
    span{
      height: 40px;
      line-height: 40px;
      border-bottom: 2px;
    }
    &.active{
      span{
        font-weight: bold;
        border-bottom: 2px solid var(--color-primary);
      }
    }
  }
}
</style>
