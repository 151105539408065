<template>
  <DialogTemplate>
    <template #title>
      <t path="leverage_12">Confirmation</t>
    </template>
    <div class="t-center">
      <t as="p" path="leverage_13" :args="{new: newlever, current: currentlever}" class="px-32 pb-20">
        Are you sure to change the leverage to Low?
        The open position will remain in miderate leverage.
      </t>
    </div>
    <template #confirmText>
      <Button class="flex-1 primary" @click="submit" :progress="progress">
        <t path="leverage_15" #="{td}" custom>{{ td || 'Yes' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import Button from '@/components/Button.vue'
import { defineComponent } from 'vue'
import { useSetLever } from '@/pages/leverage/leverage.api'

export default defineComponent({
  name: 'LeverageConfirmDailog',
  components: { DialogTemplate, Button },
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
    newlever: String,
    currentlever: String,
  },
  emits: ['success'],
  setup (props, ctx) {
    const { request, progress } = useSetLever()
    const submit = () => {
      request({
        type: props.activeIndex + 1,
      }).then(() => {
        ctx.emit('success')
      })
    }
    return {
      submit,
      progress,
    }
  },
})
</script>
