<template>
  <div class="br-lg item-main p-16 c-text" :class="{'active':item.active}" @click="handleChoose">
    <div class="title df-middle">
      <span class="checkbox mr-8"></span>
      <t :path="item.titleLable" class="f-bold">{{item.title}}</t>
    </div>
    <div class="sub-title mt-8">
      <t :path="item.subTitleLable" class="f-md">{{item.subTitle}}</t>
    </div>
    <div class="exp mt-12 mb-4 df-between">
      <div class="exp-item flex-1 mr-4 df-between df-middle px-16 br-sm">
        <span>{{item.assets[0].code}}</span>
        <span>{{item.assets[0].leverage}}</span>
      </div>
      <div class="exp-item flex-1 df-between df-middle px-16 br-sm">
        <span>{{item.assets[1].code}}</span>
        <span>{{item.assets[1].leverage}}</span>
      </div>
    </div>
    <div class="exp df-between">
      <div class="exp-item flex-1 mr-4 df-between df-middle px-16 br-sm">
        <span>{{item.assets[2].code}}</span>
        <span>{{item.assets[2].leverage}}</span>
      </div>
      <div class="exp-item flex-1 df-between df-middle px-16 br-sm">
        <span>{{item.assets[3].code}}</span>
        <span>{{item.assets[3].leverage}}</span>
      </div>
    </div>
    <t path="leverage_29" class="current px-8" v-if="item.choosed">Current</t>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'componentName',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  setup (props, ctx) {
    const handleChoose = () => {
      if (props.item.active) {
        return false
      } else {
        ctx.emit('change', props.item.index)
      }
    }
    return {
      handleChoose,
    }
  },
})
</script>

<style scoped lang="scss">
.item-main{
  border: 1px solid var(--color-border);
  position: relative;
  &.active{
    border: 1px solid var(--color-primary);
    .title{
      .checkbox{
        border: 1px solid var(--color-primary);
        position: relative;
      }
      .checkbox::after{
        height: 12px;
        width: 12px;
        background-color: var(--color-primary);
        position: absolute;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        content: '';
      }
    }
  }
  .title{
    height: 24px;
    .checkbox{
      border: 1px solid var(--color-border);
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
  }
  .exp{
    .exp-item{
      background-color: var(--color-background);
      height: 32px;
    }
  }

  .current{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-primary-thin);
    color: var(--color-primary);
    height: 24px;
    line-height: 24px;
    border-radius: 0px 6px 0px 12px;
  }
}
</style>
