
import { defineComponent, reactive, shallowRef } from 'vue'
import Button from '@/components/Button.vue'
import HoldOn from '@/provider/HoldOn.vue'
import TheAdjustmentItem from '@/pages/leverage/components/TheAdjustmentItem.vue'
import { openDialog, showAlert } from '@/components/popup/popup'
import LeverageConfirmDailog from '@/pages/leverage/components/LeverageConfirmDailog.vue'
import { readLever } from '@/pages/leverage/leverage.api'
import Pic from '@/components/Pic.vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheAdjustment',
  components: {
    TheAdjustmentItem,
    Button,
    HoldOn,
    Pic,
  },
  setup () {
    const listData = reactive([{
      index: 0,
      title: 'Low',
      titleLable: 'leverage_5',
      subTitleLable: 'leverage_6',
      active: false,
      choosed: false,
      subTitle: 'Friendly to newbies, Good for Investors with low risk appetite',
      assets: [{
        code: 'USDJPY',
        leverage: '100:1',
      }, {
        code: 'Gold',
        leverage: '48:1',
      }, {
        code: 'Bitcoin',
        leverage: '37:1',
      }, {
        code: 'Facebook',
        leverage: '10:1',
      }],
    }, {
      index: 1,
      title: 'Moderate',
      titleLable: 'leverage_7',
      subTitleLable: 'leverage_8',
      active: false,
      choosed: false,
      subTitle: 'Friendly to newbies, Good for Investors with low risk appetite',
      assets: [{
        code: 'USDJPY',
        leverage: '200:1',
      }, {
        code: 'Gold',
        leverage: '96:1',
      }, {
        code: 'Bitcoin',
        leverage: '74:1',
      }, {
        code: 'Facebook',
        leverage: '20:1',
      }],
    }, {
      index: 2,
      title: 'High',
      titleLable: 'leverage_9',
      subTitleLable: 'leverage_10',
      active: false,
      choosed: false,
      subTitle: 'Friendly to newbies, Good for Investors with low risk appetite',
      assets: [{
        code: 'USDJPY',
        leverage: '400:1',
      }, {
        code: 'Gold',
        leverage: '192:1',
      }, {
        code: 'Bitcoin',
        leverage: '148:1',
      }, {
        code: 'Facebook',
        leverage: '40:1',
      }],
    }])
    const submitActive = shallowRef(false)
    const options = shallowRef()
    let activeIndex = 0
    let currentChoose = 0

    const itemChange = (index: number) => {
      listData.forEach(item => {
        item.active = false
      })
      listData[index].active = true
      activeIndex = index
      submitActive.value = !listData[index].choosed
    }

    const setChoosedItem = (index: number) => {
      listData.forEach(item => {
        item.active = false
        item.choosed = false
      })
      listData[index].active = true
      listData[index].choosed = true
      currentChoose = index
      submitActive.value = !listData[index].choosed
    }

    readLever().then(res => {
      res.forEach((item, index) => {
        if (item.selected) {
          setChoosedItem(index)
          options.value = res
        }
      })
    })

    const confirmSubmit = () => {
      if (!submitActive.value) {
        return false
      }
      openDialog(LeverageConfirmDailog, {
        activeIndex: activeIndex,
        newlever: translate(listData[activeIndex].titleLable),
        currentlever: translate(listData[currentChoose].titleLable),
        onSuccess: () => {
          setChoosedItem(activeIndex)
          showAlert(translate('toast_13'))
        },
      })
    }
    return {
      listData,
      submitActive,
      options,
      itemChange,
      confirmSubmit,
    }
  },
})
