<template>
  <div class="p-16">
    <t class="f-big" style="line-height: 24px;" path="leverage_16">Friendly to newbies, Good for Investors with low risk appetite Friendly to newbies, Good for Investors with low risk appetite Friendly to newbies, Good for Investors with low risk appetite.</t>
    <Pic class="my-8" src="help/leverage_learn" style="width: 100%;" />
    <t as="div" path="leverage_17" multiline #="{td}" custom>
      <p class="f-big mb-8" style="line-height: 24px;" v-for="(p, index) in td" :key="index">{{p}}</p>
    </t>
    <table class="br-lg mt-16 f-md">
      <thead>
        <tr class="dark">
          <th style="border-left: none;"></th>
          <th>Low</th>
          <th>Moderate</th>
          <th>High</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in tableData" :key="index"
        :class="{'dark': index%2==1}">
          <td class="pl-12 c-title" style="text-align: left;border-left: none;">{{ item.title }}</td>
          <td>{{ item.low }}</td>
          <td>{{ item.moderate }}</td>
          <td>{{ item.high }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLeverLearn',
  components: {
    Pic,
  },
  setup () {
    return {
      tableData: [{
        title: 'Margin',
        low: '$10',
        moderate: '$10',
        high: '$10',
      }, {
        title: 'Volume',
        low: '0.25 oz',
        moderate: '0.5 oz',
        high: '1 oz',
      }, {
        title: 'Buying power',
        low: '$465',
        moderate: '$930',
        high: '$1860',
      }, {
        title: 'Leverage',
        low: '46.5:1',
        moderate: '93:1',
        high: '186:1',
      }, {
        title: 'Profit per pip',
        low: '$0.025',
        moderate: '$0.05',
        high: '$0.1',
      }, {
        title: 'Profit',
        low: '$10',
        moderate: '$20',
        high: '$40',
      }, {
        title: 'Profit%',
        low: '100%',
        moderate: '200%',
        high: '400%',
      }],
    }
  },
})
</script>

<style scoped lang="scss">
table{
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--color-border);
  tr{
    line-height: 32px;
  }
  td,th{
    text-align: center;
    border-left: 1px solid var(--color-border);
  }
  thead{
    width: 100%;
  }
  tbody{
    width: 100%;
  }
  .dark{
    background-color: var(--color-light);
  }
}
</style>
